import config from '@/__config'
const model = {
  modelName: "tulpa_cross_item",
  label: "跨網站項目",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "項目名稱",
      rules: 'required',
      labelInLocale: true,
    },
    position: {
      type: 'select',
      label: 'Item Position',
      rules: 'required',
      items: [
        {
          value: 'bottom',
          text: 'Bottom'
        },
        {
          value: 'fixed_at_top',
          text: 'Fixed At Top'
        },
      ]
    },
    content: {
      type: "payload",
      label: "content",
    },
    tulpa_section: {
      type: 'belongsTo',
      label: "Tulpa Section",
      textKey: "name",
      rules: 'required',
      modelName: "tulpa_section",
      labelInLocale: true,
    },
    updated_at: {
      type: "datetime",
      label: "updated_at",
      readonly: true,
      labelInLocale: true,
    },
  }
}

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'cmser_group',
      labelInLocale: true,
    }
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'admin_group',
      labelInLocale: true,
    }
  }
}

export default model