<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :inRowBtnRead="false"
      :expandable="false"
      :showExpand="false"
      :pageMode="true"
      :labelInLocale="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_cross_item";
export default {
  metaInfo() {
    return {
      title: this.$t("Page"),
    };
  },

  data: () => ({
    ...model,
    // modelName: model.modelName,
    // label: model.label,
    // fields: model.fields,
    showFields: ["name"],
  }),
  computed: {},
};
</script>